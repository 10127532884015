import { ChangeDetectionStrategy, Component, effect, inject, Injector, isDevMode, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';

import { ToastComponent } from '@ideals/components/toast';
import { AuthService } from '@ideals/core/auth';
import { ClarityService } from '@ideals/core/clarity';
import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { GoogleAnalyticsService } from '@ideals/core/google-analytics';
import { HeapService } from '@ideals/core/heap';
import { IntercomService } from '@ideals/core/intercom';
import { LocalStorageService } from '@ideals/services/local-storage';

import {
  HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY,
  HIDE_DOCUMENTS_BULK_RENAME_TRIAL_PROMOTION_KEY,
  HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY,
} from './data/models';
import { OperationTrackerService } from './data/services/operation-tracker';
import { AuthUserStore } from './data/store/auth-user';
import { getPermissionsUpdateCountKey } from './data/utils/get-permissions-update-count-key';
import { environment } from './environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ideals-app-root',
  },
  imports: [
    RouterOutlet,
    ToastComponent,
  ],
  selector: 'vdr-root',
  standalone: true,
  templateUrl: './root.container.html',
})
export class RootContainer implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #authUserStore = inject(AuthUserStore);
  readonly #clarityService = inject(ClarityService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #googleAnalyticsService = inject(GoogleAnalyticsService);
  readonly #heapService = inject(HeapService);
  readonly #injector = inject(Injector);
  readonly #intercomService = inject(IntercomService);
  readonly #localStorageService = inject(LocalStorageService);
  readonly #operationTrackerService = inject(OperationTrackerService);

  ngOnInit(): void {
    if (this.#authService.isLoggedIn && !this.#authService.hasRole('guest')) {
      this.#authUserStore.loadAuthUser();
    }

    this.#authService.events$
      .pipe(filter((event) => ['logout', 'session_error'].includes(event.type)))
      .subscribe(() => {
        this.#localStorageService.removeItem(HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY);
        this.#localStorageService.removeItem(HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY);
        this.#localStorageService.removeItem(HIDE_DOCUMENTS_BULK_RENAME_TRIAL_PROMOTION_KEY);
        this.#operationTrackerService.reset('vdr-documents-operations-count');
      });

    if (environment.intercomEnabled) {
      this.#intercomService.initialize();
    }

    if (this.#featureFlagsService.isEnabled('fvdr-orion-heap-enabled') && !isDevMode()) {
      this.#heapService.initialize(environment.heapKey);
    }

    if (this.#featureFlagsService.isEnabled('fvdr-altair-ga-enabled') && !isDevMode()) {
      this.#googleAnalyticsService.initialize(environment.googleTagId);
    }

    // TODO: move to auth service initialize method when cleaning up FF
    if (this.#featureFlagsService.isEnabled('fvdr-altair-idle-enabled')) {
      this.#authService.startVisibilityTracking();
    }

    this.#effects();
  }

  #effects(): void {
    effect(() => {
      const authUser = this.#authUserStore.user();

      if (authUser) {
        this.#heapService.identify(authUser.id);
        this.#clarityService.identify(authUser.id);
        this.#googleAnalyticsService.identify(authUser.id);
        this.#operationTrackerService.setup({
          'vdr-documents-operations-count': 'number_of_move_copy_restore_operations',
          [getPermissionsUpdateCountKey(authUser.id)]: 'apply_permission_count_admin',
        });
      }
    }, { allowSignalWrites: true, injector: this.#injector });
  }
}

/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { diag } from '@opentelemetry/api';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
var InstrumentationMap = {
  '@opentelemetry/instrumentation-document-load': DocumentLoadInstrumentation,
  '@opentelemetry/instrumentation-fetch': FetchInstrumentation,
  '@opentelemetry/instrumentation-user-interaction': UserInteractionInstrumentation,
  '@opentelemetry/instrumentation-xml-http-request': XMLHttpRequestInstrumentation
};
export function getWebAutoInstrumentations(inputConfigs) {
  var _a;
  if (inputConfigs === void 0) {
    inputConfigs = {};
  }
  for (var _i = 0, _b = Object.keys(inputConfigs); _i < _b.length; _i++) {
    var name_1 = _b[_i];
    if (!Object.prototype.hasOwnProperty.call(InstrumentationMap, name_1)) {
      diag.error("Provided instrumentation name \"" + name_1 + "\" not found");
      continue;
    }
  }
  var instrumentations = [];
  for (var _c = 0, _d = Object.keys(InstrumentationMap); _c < _d.length; _c++) {
    var name_2 = _d[_c];
    var Instance = InstrumentationMap[name_2];
    // Defaults are defined by the instrumentation itself
    var userConfig = (_a = inputConfigs[name_2]) !== null && _a !== void 0 ? _a : {};
    if (userConfig.enabled === false) {
      diag.debug("Disabling instrumentation for " + name_2);
      continue;
    }
    try {
      diag.debug("Loading instrumentation for " + name_2);
      instrumentations.push(new Instance(userConfig));
    } catch (e) {
      diag.error(e);
    }
  }
  return instrumentations;
}

import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';

import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { SessionStorageService } from '@ideals/services/session-storage';
import { uniqueId } from '@ideals/utils/unique-id';

declare global {
  interface Window {
    // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
    clarity: Clarity;
  }
}

interface ClarityCommandSignature {
  readonly identify: (userId: string, sessionId?: string) => Promise<{ readonly id: string; readonly userHint: string; }>;
}

type ClarityCommand = keyof ClarityCommandSignature;

interface Clarity {
  <Command extends ClarityCommand>(
    command: Command,
    ...params: Parameters<ClarityCommandSignature[Command]>
  ): ReturnType<ClarityCommandSignature[Command]>;
  // eslint-disable-next-line custom-rules/prefer-interface-property-readonly
  q: unknown[];
}

@Injectable({ providedIn: 'root' })
export class ClarityService {
  readonly #document = inject(DOCUMENT);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #renderer2 = inject(RendererFactory2).createRenderer(null, null);
  readonly #sessionStorageService = inject(SessionStorageService);

  identify(userId: string): void {
    if (!window.clarity) {
      return;
    }

    if (!userId) {
      console.warn('ClarityService.identify: userId is not defined');
    }

    if (this.#featureFlagsService.isEnabled('fvdr-orion-clarity-without-cookies-enabled')) {
      window.clarity('identify', userId, this.#getSessionId(userId));
    } else {
      window.clarity('identify', userId);
    }
  }

  initialize(key: string): void {
    this.#createClarityScript(key);
  }

  #createClarityScript(key: string): void {
    const script = this.#renderer2.createElement('script') as HTMLScriptElement;
    const scriptSrc = `https://www.clarity.ms/tag/${key}`;

    this.#renderer2.setAttribute(script, 'async', '');
    this.#renderer2.setAttribute(script, 'src', scriptSrc);
    this.#renderer2.appendChild(this.#document.head, script);

    window.clarity = window.clarity || ((...args: unknown[]): void => {
      (window.clarity.q = window.clarity.q || []).push(args);
    });
  }

  #getSessionId(userId: string): string {
    const sessionId = this.#sessionStorageService.getItem<string>('vdr-clarity-session') ?? `${userId}--${uniqueId()}`;

    this.#sessionStorageService.setItem('vdr-clarity-session', sessionId);

    return sessionId;
  }
}

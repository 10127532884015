import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, isDevMode, Provider } from '@angular/core';
import StackTrace from 'stacktrace-js';

import { LoggerService } from '../../services/logger';

const IGNORED_ERROR_PATTERNS = ['/node_modules/'];
const CHUNK_LOADING_ERRORS: RegExp[] = [
  /Loading chunk [\w-]+ failed/,
  /Failed to fetch dynamically imported module/,
];

export class CustomErrorHandler implements ErrorHandler {
  readonly #loggerService = inject(LoggerService);

  handleError(error: Error | HttpErrorResponse): void {
    if (CHUNK_LOADING_ERRORS.some((chunkError) => chunkError.test(error.message))) {
      window.location.reload();

      return;
    }
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      return;
    }

    if (!isDevMode() && error instanceof Error) {
      StackTrace.fromError(error)
        .then((frames) => {
          const exception = frames.map((frame) => frame.toString())[0] ?? '';

          if (IGNORED_ERROR_PATTERNS.some((pattern) => exception.includes(pattern))) {
            return;
          }

          this.#loggerService.error({ message: error.message, exception });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
}

export function provideErrorHandler(): Provider[] {
  return [{
    provide: ErrorHandler,
    useClass: CustomErrorHandler,
  }];
}
